import React, { useState, useEffect } from "react";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import api from "../../../../CommonApi/axios";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import headers from "../../../../CommonApi/headers";
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import { Button, Card, Col, Form, Modal, ModalHeader, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import useDrawer from "../../../../context/DrawerContext";
import MaterialTable from "material-table";
import { ListItemIcon, ListItemText } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CopyIcon from "@material-ui/icons/FileCopy";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
// import moment from "moment";
import { Menu, MenuItem } from "@mui/material";
// import { TourGroupsPermissions, userPermissionType } from "../../../Comman/UserPermission";
import { TourGroupsPermissions, userPermissionType } from "../../../Comman/UserPermission";

window.jquery = window.$ = $;

const ActionIcon = () => {
    return (
      <button className="btn btn-outline btn-outline-secondary px-2 py-1">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.28149 4.7998C2.42233 4.7998 1.96315 5.81172 2.52891 6.45831L6.94637 11.5068C7.50415 12.1443 8.49581 12.1443 9.05359 11.5068L13.4711 6.45831C14.0368 5.81173 13.5776 4.7998 12.7185 4.7998H3.28149Z"
            fill="#667085"
          />
        </svg>
      </button>
    );
};

export default function GroupAdminTourDetails() {

    var pathname = window.location.pathname;
    var pathnamesplits = pathname.split("/");
    var url = pathnamesplits[3];

    const userPermissionType_ = userPermissionType === "groupadmin"

    let history = useHistory();
    const { tour_id } = useParams();
    useEffect(() => {
        AllList();
    }, []);
    useEffect(() => {
        Commentist();
    }, []);

    const [tourDetails, setTourDetails] = useState({});
    const [comanyDetais, setCompanyDetails] = useState({});
    const [clientDetais, setClientDetails] = useState({});
    const [commentsList, setCommentsList] = useState([]);

    const [addComments, setAddComments] = useState(false);

    const [editComments, setEditComments] = useState(false);

    // const [anchorEl, setAnchorEl] = useState(null);
    // const [selectedRow, setSelectedRow] = useState(null);

    // const handleMenuOpen = (event, rowData) => {
    //     setAnchorEl(event.currentTarget);
    //     setSelectedRow(rowData);
    // };

    

    const columns = [
        {
            title: "Sr No.",
            render: (rowData) => {
                return <>{rowData.tableData.id + 1}</>;
            },
        },
        {
          title: "Comments",
          render: (rowData) => {
            // /group_admin/tour/comments/:tour_id
            return (
              <>
                {rowData?.comment}
                
              </>
            );
          },
        },
        {
            title: "Created By",
            render: (rowData) => {
              // /group_admin/tour/comments/:tour_id
              return (
                <>
                    {rowData?.created_by_name},&nbsp;&nbsp;{moment(rowData?.created_at).tz(rowData?.timezone).format('DD/MM/YYYY  hh:MM A')}                  
                </>
              );
            },
          },
          {
            title: "Updated By",
            render: (rowData) => {
              // /group_admin/tour/comments/:tour_id
              return (
                <>
                    {rowData?.updated_by_name ? rowData?.updated_by_name : rowData?.created_by_name},&nbsp;&nbsp;{moment(rowData?.updated_at).tz(rowData?.timezone).format('DD/MM/YYYY  hh:MM A')}                  
                </>
              );
            },
          },
      ];

    async function AllList() {


        try {
            const tourData = await api.post(`/groupadmin/tourgroup/detail_tour`, { tour_id: tour_id }, { headers });
            const TourDetails = tourData?.data?.data;

            setTourDetails(TourDetails)
            setCompanyDetails(TourDetails.companyinfo)
            setClientDetails(TourDetails.clientinfo)

        } catch (err) {

        }

    }

    async function Commentist() {

        try {
            const Comment = await api.post(`/groupadmin/tourgroup/list_comment_by_tourid`, { tour_id: tour_id }, { headers });
            const CommentsDetails = Comment?.data?.data;
            setCommentsList(CommentsDetails)
            console.log(commentsList)
        } catch (err) {
            if (err.response) {

            }
        }
    }

    const [formData, setFormData] = useState({
        tour_id: tour_id,
        comment: '',
    });

    const [editformData, setEditFormData] = useState({
        comment_id: '',
        comment: '',
    });

    // const userPermissionType_ = userPermissionType === "groupadmin"


    const [errorForm, setErrorForm] = useState({});
    const [loading, setLoading] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    
    const [selectedRow, setSelectedRow] = useState(null);

    const handleMenuOpen = (event, rowData) => {
        setAnchorEl(event.currentTarget);
        setSelectedRow(rowData);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleInputChange = (e, fieldName) => {
        const { name, value } = e.target; // Destructuring name and value from e.target
        // Logging name and value
        setErrorForm(prevErrorForm => ({ ...prevErrorForm, [name]: null }));
        if(addComments){
            setFormData({ ...formData, [name]: value });
        }
        else if(editComments){
            setEditFormData({...editformData,[name]:value})
        }
    };

    const [apiError, setApiError] = useState('');
    const errorDiv = apiError ? (
        <div className="text-center alert alert-danger">{apiError}</div>
    ) : (
        ""
    );

    function handleSubmit(event) {
        // console.log(event.)
        event.preventDefault();
        setLoading(true);
        setErrorForm(null);
        // Check for validation errors for each field
        const errors = {};

        if(addComments){
            if (formData.comment.trim() === '') {
                errors.comment = "Please Add Comment*.";
            }
        }
        else if(editComments){
            if (editformData.comment.trim() === '') {
                errors.comment = "Please Add Comment*.";
            }
        }

        // Update formErrors state with individual field errors
        setErrorForm(errors);

        // If there are any errors, prevent form submission
        if (Object.keys(errors).length > 0) {
            setLoading(false);
            return;
        }

        Swal.fire({
            title: 'Please wait...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            showConfirmButton: false,
            willOpen: () => {
                Swal.showLoading();
            }
        });

        if(addComments){
            api
                .post(`/groupadmin/tourgroup/add_comment_by_tourid/`, formData, { headers })
                .then((res) => {
                    setAddComments(false)
                    Commentist()
                    setFormData(prevFormData => ({
                        ...prevFormData,
                        comment: '' // Set email to blank
                    }));
    
                    Swal.fire({
                        position: "top-center",
                        icon: "success",
                        title: "Tour Comment Added successfully",
                        showConfirmButton: false,
                        timer: 1500,
                    }).then(() => {
                        setLoading(false)
                        // history.push("/group_admin/settings/tourSteup/CompanyList");
                        setAddComments(false)
                    });
                    // Redirect to the CompanyList page
                })
                .catch((err) => {
                    setLoading(false)
                    if (err.response) {
                        Swal.close()
                        toast.error(err?.response?.data?.message, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    }
                });
        }
        else if(editComments){
            api
                .post(`/groupadmin/tourgroup/edit_comment`, editformData, { headers })
                .then((res) => {
                    setEditComments(false)
                    Commentist()
                    setFormData(prevFormData => ({
                        ...prevFormData,
                        comment: '' // Set email to blank
                    }));
    
                    Swal.fire({
                        position: "top-center",
                        icon: "success",
                        title: "Tour Comment Edited successfully",
                        showConfirmButton: false,
                        timer: 1500,
                    }).then(() => {
                        setLoading(false)
                        // history.push("/group_admin/settings/tourSteup/CompanyList");
                        setEditComments(false)
                    });
                    // Redirect to the CompanyList page
                })
                .catch((err) => {
                    setLoading(false)
                    if (err.response) {
                        Swal.close()
                        toast.error(err?.response?.data?.message, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    }
                });
        }
    }

    function deleteComment(_id) {
        setAnchorEl(null);
        if(selectedRow){

            Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.isConfirmed) {
                    console.log(_id)
    
                    api
                    .post(
                        `/groupadmin/tourgroup/delete_comment`,
                        { comment_id: _id },
                        { headers }
                    )
                    .then((result) => {
                        Swal.fire({
                        position: "center-center",
                        icon: "success",
                        title: "Comment has been deleted successfully.",
                        showConfirmButton: false,
                        timer: 1500,
                        });
                        Commentist();
                    })
                    .catch((err) => {
                        if (err.response) {
                        toast.error(err?.response?.data?.error?.message, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        }
                    });
                }
            });
        }
    }
    const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();


    return (
        <React.Fragment>
            <div
                id="kt_body"
                className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
            >
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
                        <div
                            className="wrapper d-flex flex-column flex-row-fluid"
                            id="kt_wrapper"
                        >
                            <GroupAdminHeader />
                            <div
                                className=" pt-0 d-flex flex-column flex-column-fluid"
                                id="kt_content"
                            >
                                <div id="kt_content_container" className="mx-7">
                                    <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                                        <div className="pb-10">
                                            <div className="d-flex flex-stack mb-7">
                                                <div
                                                    className="d-flex justify-content-end"
                                                    data-kt-docs-table-toolbar="base"
                                                >
                                                    <h1
                                                        className="anchor fw-bolder mb-0"
                                                        id="custom-form-control"
                                                    >
                                                        {url === 'details' ? 'Tour Group Details' : 'Tour Group Comments'}
                                                    </h1>
                                                </div>
                                                <div className="d-flex flex-stack">
                                                    <div
                                                        className="d-flex justify-content-end mx-2"
                                                        data-kt-docs-table-toolbar="base"
                                                    >
                                                        <button
                                                            onClick={() => history.goBack()}
                                                            className="btn btn-primary"
                                                            data-kt-menu-trigger="click"
                                                            data-kt-menu-attach="parent"
                                                            data-kt-menu-placement="bottom-end"
                                                        >
                                                            Go Back
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="py-5">
                                                <div className="card card-docs mb-2">
                                                    <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                                                        {url === 'details' ? (
                                                            <div className="rounded border p-10">
                                                                <div className="row">

                                                                    {(userPermissionType_ || TourGroupsPermissions?.access?.includes("create"))
                                                                        &&
                                                                        <div className="col-12 mb-10">
                                                                            <h4>Tour No&nbsp;-&nbsp;{tourDetails.tour_no}&nbsp;</h4>
                                                                        </div>}

                                                                    <div className="col-12">
                                                                        <div className="row px-5 mb-10 5 pb-5">
                                                                            <div className="col-12 col-md-6">
                                                                                <h3 className="pb-1">
                                                                                    Company
                                                                                </h3>
                                                                                {comanyDetais.company_name && (
                                                                                    <h3 className="text-gray-600 pb-1" >{comanyDetais.company_name}</h3>
                                                                                )}

                                                                                {(userPermissionType_ || TourGroupsPermissions?.access?.includes("create")) &&
                                                                                    <>
                                                                                        {comanyDetais.address1 && (
                                                                                            <h3 className="text-gray-600 pb-1" style={{ fontWeight: 400, }}>{comanyDetais.address1}</h3>
                                                                                        )}
                                                                                        {comanyDetais.address2 && (
                                                                                            <h3 className="text-gray-600 pb-1" style={{ fontWeight: 400, }}>{comanyDetais.address2}</h3>
                                                                                        )}
                                                                                        {comanyDetais.address3 && (
                                                                                            <h3 className="text-gray-600 pb-1" style={{ fontWeight: 400, }}>{comanyDetais.address3}</h3>
                                                                                        )}
                                                                                        {comanyDetais.email && (
                                                                                            <h3 className="text-gray-600 pb-1" style={{ fontWeight: 400, }}>{comanyDetais.email}</h3>
                                                                                        )}
                                                                                        {comanyDetais.phone && (
                                                                                            <h3 className="text-gray-600 pb-1" style={{ fontWeight: 400, }}>{comanyDetais.phone}</h3>
                                                                                        )}
                                                                                        {comanyDetais.mobile && (
                                                                                            <h3 className="text-gray-600 pb-1" style={{ fontWeight: 400, }}>{comanyDetais.mobile}</h3>
                                                                                        )}</>}
                                                                            </div>
                                                                            {(userPermissionType_ || TourGroupsPermissions?.access?.includes("create")) &&
                                                                                <div className="col-12 col-md-6">
                                                                                    <h3 className="pb-1">
                                                                                        Client
                                                                                    </h3>
                                                                                    {clientDetais.client_name && (
                                                                                        <h3 className="text-gray-600 pb-1" >{clientDetais.client_name}</h3>
                                                                                    )}

                                                                                    {clientDetais.address1 && (
                                                                                        <h3 className="text-gray-600 pb-1" style={{ fontWeight: 400, }}>{clientDetais.address1}</h3>
                                                                                    )}
                                                                                    {clientDetais.address2 && (
                                                                                        <h3 className="text-gray-600 pb-1" style={{ fontWeight: 400, }}>{clientDetais.address2}</h3>
                                                                                    )}
                                                                                    {clientDetais.address3 && (
                                                                                        <h3 className="text-gray-600 pb-1" style={{ fontWeight: 400, }}>{clientDetais.address3}</h3>
                                                                                    )}
                                                                                    {clientDetais.email && (
                                                                                        <h3 className="text-gray-600 pb-1" style={{ fontWeight: 400, }}>{clientDetais.email}</h3>
                                                                                    )}
                                                                                    {clientDetais.phone && (
                                                                                        <h3 className="text-gray-600 pb-1" style={{ fontWeight: 400, }}>{clientDetais.phone}</h3>
                                                                                    )}
                                                                                    {clientDetais.mobile && (
                                                                                        <h3 className="text-gray-600 pb-1" style={{ fontWeight: 400, }}>{clientDetais.mobile}</h3>
                                                                                    )}
                                                                                </div>}

                                                                        </div>
                                                                        <div className="row px-5 mb-2 pb-5">
                                                                            <div className="col-12 col-sm-6 col-md-4">
                                                                                <h5 className="pb-1" style={{ fontWeight: 500, }}>
                                                                                    Date
                                                                                </h5>
                                                                                {tourDetails.tour_date !== '' && (
                                                                                    <h3 className="text-gray-600 pb-1" style={{ fontWeight: 400, }}>{moment(tourDetails.tour_date).format('DD/MM/YYYY')}</h3>
                                                                                )}

                                                                            </div>
                                                                            <div className="col-12 col-sm-6 col-md-4">
                                                                                <h5 className="pb-1" style={{ fontWeight: 500, }}>
                                                                                    Reference No
                                                                                </h5>
                                                                                {tourDetails.reference !== '' ? (
                                                                                    <h3 className="text-gray-600 pb-1" style={{ fontWeight: 400, }}>{tourDetails.reference}</h3>
                                                                                ) : (<span>---</span>)}

                                                                            </div>
                                                                            <div className="col-12 col-sm-6 col-md-4">
                                                                                <h5 className="pb-1" style={{ fontWeight: 500, }}>
                                                                                    Referral Code
                                                                                </h5>
                                                                                {tourDetails.referral_code !== '' ? (
                                                                                    <h3 className="text-gray-600 pb-1" style={{ fontWeight: 400, }}>{tourDetails.referral_code}</h3>
                                                                                ) : (<span>---</span>)}

                                                                            </div>
                                                                        </div>
                                                                        <div className="row px-5 mb-2 pb-5">
                                                                            <div className="col-12 col-sm-6 col-md-4">
                                                                                <h5 className="pb-1" style={{ fontWeight: 500, }}>
                                                                                    Dinning Location
                                                                                </h5>
                                                                                {tourDetails.dinning_location !== '' ? (
                                                                                    <h3 className="text-gray-600 pb-1" style={{ fontWeight: 400, }}>{tourDetails.dinning_location}</h3>
                                                                                ) : (<span>---</span>)}

                                                                            </div>
                                                                            <div className="col-12 col-sm-6 col-md-4">
                                                                                <h5 className="pb-1" style={{ fontWeight: 500, }}>
                                                                                    Cooking Location
                                                                                </h5>
                                                                                {tourDetails.cooking_location !== '' ? (
                                                                                    <h3 className="text-gray-600 pb-1" style={{ fontWeight: 400, }}>{tourDetails.cooking_location}</h3>
                                                                                ) : (<span>---</span>)}

                                                                            </div>
                                                                            <div className="col-12 col-sm-6 col-md-4">
                                                                                <h5 className="pb-1" style={{ fontWeight: 500, }}>
                                                                                    Meal
                                                                                </h5>
                                                                                {tourDetails.meal_name !== '' ? (
                                                                                    <h3 className="text-gray-600 pb-1" style={{ fontWeight: 400, }}>{tourDetails.meal_name}</h3>
                                                                                ) : (<span>---</span>)}

                                                                            </div>
                                                                        </div>
                                                                        <div className="row px-5 mb-2 pb-5">
                                                                            <div className="col-12 col-sm-6 col-md-4">
                                                                                <h5 className="pb-1" style={{ fontWeight: 500, }}>
                                                                                    Status
                                                                                </h5>
                                                                                {tourDetails.status !== '' ? (
                                                                                    <h3 className="text-gray-600 pb-1" style={{ fontWeight: 400, }}>{tourDetails.status}</h3>
                                                                                ) : (<span>---</span>)}

                                                                            </div>
                                                                            <div className="col-12 col-sm-6 col-md-4">
                                                                                <h5 className="pb-1" style={{ fontWeight: 500, }}>
                                                                                    Manager Name
                                                                                </h5>
                                                                                {tourDetails.tour_manager !== '' ? (
                                                                                    <h3 className="text-gray-600 pb-1" style={{ fontWeight: 400, }}>{tourDetails.tour_manager}</h3>
                                                                                ) : (<span>---</span>)}

                                                                            </div>
                                                                            <div className="col-12 col-sm-6 col-md-4">
                                                                                <h5 className="pb-1" style={{ fontWeight: 500, }}>
                                                                                    Manager Contact Number
                                                                                </h5>
                                                                                {tourDetails.tour_manager_no !== '' ? (
                                                                                    <h3 className="text-gray-600 pb-1" style={{ fontWeight: 400, }}>{tourDetails.tour_manager_no}</h3>
                                                                                ) : (<span>---</span>)}

                                                                            </div>
                                                                        </div>
                                                                        <div className="row px-5 mb-2 pb-5">
                                                                            <div className="col-12 col-sm-6 col-md-4">
                                                                                <h5 className="pb-1" style={{ fontWeight: 500, }}>
                                                                                    No. of Adults
                                                                                </h5>
                                                                                {tourDetails.adult !== '' ? (
                                                                                    <h3 className="text-gray-600 pb-1" style={{ fontWeight: 400, }}>{tourDetails.adult}</h3>
                                                                                ) : (<span>---</span>)}

                                                                            </div>
                                                                            <div className="col-12 col-sm-6 col-md-4">
                                                                                <h5 className="pb-1" style={{ fontWeight: 500, }}>
                                                                                    No. of Kids
                                                                                </h5>
                                                                                {tourDetails.kid !== '' ? (
                                                                                    <h3 className="text-gray-600 pb-1" style={{ fontWeight: 400, }}>{tourDetails.kid}</h3>
                                                                                ) : (<span>---</span>)}

                                                                            </div>
                                                                            <div className="col-12 col-sm-6 col-md-4">
                                                                                <h5 className="pb-1" style={{ fontWeight: 500, }}>
                                                                                    Total People
                                                                                </h5>
                                                                                {tourDetails.total_pax !== '' ? (
                                                                                    <h3 className="text-gray-600 pb-1" style={{ fontWeight: 400, }}>{tourDetails.total_pax}</h3>
                                                                                ) : (<span>---</span>)}

                                                                            </div>
                                                                        </div>
                                                                        <div className="row px-5 mb-2 pb-5">
                                                                            <div className="col-12 col-sm-6 col-md-4">
                                                                                <h5 className="pb-1" style={{ fontWeight: 500, }}>
                                                                                    No of Vegetarian Meals
                                                                                </h5>
                                                                                {tourDetails.veg !== '' ? (
                                                                                    <h3 className="text-gray-600 pb-1" style={{ fontWeight: 400, }}>{tourDetails.veg}</h3>
                                                                                ) : (<span>---</span>)}

                                                                            </div>
                                                                            <div className="col-12 col-sm-6 col-md-4">
                                                                                <h5 className="pb-1" style={{ fontWeight: 500, }}>
                                                                                    No of Jain Meals
                                                                                </h5>
                                                                                {tourDetails.jain !== '' ? (
                                                                                    <h3 className="text-gray-600 pb-1" style={{ fontWeight: 400, }}>{tourDetails.jain}</h3>
                                                                                ) : (<span>---</span>)}

                                                                            </div>
                                                                            <div className="col-12 col-sm-6 col-md-4">
                                                                                <h5 className="pb-1" style={{ fontWeight: 500, }}>
                                                                                    No of Non Vegetarian Meals
                                                                                </h5>
                                                                                {tourDetails.non_veg !== '' ? (
                                                                                    <h3 className="text-gray-600 pb-1" style={{ fontWeight: 400, }}>{tourDetails.non_veg}</h3>
                                                                                ) : (<span>---</span>)}

                                                                            </div>
                                                                        </div>
                                                                        {(userPermissionType_ || TourGroupsPermissions?.access?.includes("create")) &&
                                                                            <>
                                                                                <div className="row px-5 mb-2 pb-5">
                                                                                    <div className="col-12 col-sm-6 col-md-4">
                                                                                        <h5 className="pb-1" style={{ fontWeight: 500, }}>
                                                                                            Adult Unit Price
                                                                                        </h5>
                                                                                        {tourDetails.adult_price !== '' ? (
                                                                                            <h3 className="text-gray-600 pb-1" style={{ fontWeight: 400, }}>{tourDetails.adult_price}</h3>
                                                                                        ) : (<span>---</span>)}

                                                                                    </div>
                                                                                    <div className="col-12 col-sm-6 col-md-4">
                                                                                        <h5 className="pb-1" style={{ fontWeight: 500, }}>
                                                                                            Kids Unit Price
                                                                                        </h5>
                                                                                        {tourDetails.kid_price !== '' ? (
                                                                                            <h3 className="text-gray-600 pb-1" style={{ fontWeight: 400, }}>{tourDetails.kid_price}</h3>
                                                                                        ) : (<span>---</span>)}

                                                                                    </div>
                                                                                    <div className="col-12 col-sm-6 col-md-4">
                                                                                        <h5 className="pb-1" style={{ fontWeight: 500, }}>
                                                                                            Total Price
                                                                                        </h5>
                                                                                        {tourDetails.total !== '' ? (
                                                                                            <h3 className="text-gray-600 pb-1" style={{ fontWeight: 400, }}>{tourDetails.total}</h3>
                                                                                        ) : (<span>---</span>)}

                                                                                    </div>
                                                                                </div>
                                                                                <div className="row px-5 mb-2 pb-5">
                                                                                    <div className="col-12 col-sm-6 col-md-4">
                                                                                        <h5 className="pb-1" style={{ fontWeight: 500, }}>
                                                                                            Payment Currency
                                                                                        </h5>
                                                                                        {tourDetails.currency !== '' ? (
                                                                                            <h3 className="text-gray-600 pb-1" style={{ fontWeight: 400, }}>{tourDetails.currency}</h3>
                                                                                        ) : (<span>---</span>)}

                                                                                    </div>
                                                                                    <div className="col-12 col-sm-6 col-md-4">
                                                                                        <h5 className="pb-1" style={{ fontWeight: 500, }}>
                                                                                            Invoice Number
                                                                                        </h5>
                                                                                        {tourDetails.invoice_number !== '' ? (
                                                                                            <h3 className="text-gray-600 pb-1" style={{ fontWeight: 400, }}>{tourDetails.invoice_number}</h3>
                                                                                        ) : (<span>---</span>)}

                                                                                    </div>
                                                                                    <div className="col-12 col-sm-6 col-md-4">
                                                                                        <h5 className="pb-1" style={{ fontWeight: 500, }}>
                                                                                            Payment Status
                                                                                        </h5>
                                                                                        {tourDetails.payment_status !== '' ? (
                                                                                            <h3 className="text-gray-600 pb-1" style={{ fontWeight: 400, }}>{tourDetails.payment_status}</h3>
                                                                                        ) : (<span>---</span>)}

                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        }
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        ) : ''}
                                                        <div className={`row ${url === 'details' ? 'py-10' : ''}`}>
                                                            <div className="col-12 d-flex mb-10" style={{ justifyContent: 'space-between', alignItems: 'center', }}>
                                                                <div>
                                                                    <h4
                                                                        className="anchor fw-bolder mb-0"
                                                                        id="custom-form-control"
                                                                    >
                                                                        Comments
                                                                    </h4>
                                                                </div>
                                                                {url === 'comments' ? (

                                                                    (userPermissionType_ ||  TourGroupsPermissions?.access?.includes("create")) &&
                                                                    <div>
                                                                        <button
                                                                            onClick={() => {
                                                                                setAddComments(true)
                                                                            }
                                                                            }
                                                                            className="btn btn-primary"
                                                                            data-kt-menu-trigger="click"
                                                                            data-kt-menu-attach="parent"
                                                                            data-kt-menu-placement="bottom-end"
                                                                        >
                                                                            Add Comment
                                                                        </button>
                                                                    </div>
                                                                ) : ''}
                                                            </div>
                                                            <div className="col-12">
                                                            <MaterialTable
                                                            title=""
                                                            data={commentsList}
                                                            columns={columns}
                                                            options={{
                                                            sorting: true,
                                                            search: false,
                                                            paging: true,
                                                            pageSizeOptions: [2, 5, 10, 20, 25, 50, 100],
                                                            pageSize: 100,
                                                            emptyRowsWhenPaging: false,
                                                            paginationType: "stepped",
                                                            showFirstLastPageButtons: false,
                                                            paginationPosition: "bottom",
                                                            actionsColumnIndex: -1,
                                                            draggable: false,
                                                            headerStyle: {
                                                                background: "darkgray",
                                                                color: "#fff",
                                                                fontSize: "15px",
                                                                paddingLeft: "10px",
                                                                paddingRight: "20px",
                                                            },
                                                            toolbar: false, // Disable built-in toolbar
                                                            }}
                                                            actions={[
                                                            {
                                                                icon: () => <ActionIcon />,
                                                                tooltip: "More",
                                                                onClick: (event, rowData) =>
                                                                handleMenuOpen(event, rowData),
                                                            },
                                                            ]}
                                                        />
                                                        {userPermissionType_ ? 
                                                        <Menu
                                                            id="simple-menu"
                                                            anchorEl={anchorEl}
                                                            className="rounded border border-2"
                                                            open={Boolean(anchorEl)}
                                                            onClose={handleMenuClose}
                                                            
                                                        >
                                                            <MenuItem
                                                            className="py-2 px-4"
                                                            //   onClick={() => handleEditTour(selectedRow._id)}
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                            }}
                                                            onClick = {() => {
                                                                setEditFormData({ comment: selectedRow?.comment, comment_id: selectedRow?._id });
                                                                setEditComments(true);
                                                                console.log(selectedRow)
                                                            }}
                                                            >
                                                            <ListItemIcon>
                                                                <EditIcon />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={
                                                                <span
                                                                    style={{
                                                                    fontSize: "14px",
                                                                    fontFamily: "inherit",
                                                                    fontWeight: 500,
                                                                    }}
                                                                >
                                                                    Edit Comment
                                                                </span>
                                                                }
                                                            />
                                                            </MenuItem>
                                                            <MenuItem
                                                            className="py-2 px-4"
                                                            //   onClick={() => handleCloneTour(selectedRow._id)}
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                            }}
                                                            onClick={() => deleteComment(selectedRow?._id)}
                                                            >
                                                            <ListItemIcon>
                                                                <DeleteIcon />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={
                                                                <span
                                                                    style={{
                                                                    fontSize: "14px",
                                                                    fontFamily: "inherit",
                                                                    fontWeight: 500,
                                                                    }}
                                                                >
                                                                    Delete Comment
                                                                </span>
                                                                }
                                                            />
                                                            </MenuItem>
                                                        </Menu> : "" }
                                                            {/* <table className="table table-hover" style={{ width: '100%', border: '1px solid #EAECF0', borderRadius: '15px', }}>
                                                                    <thead className="" style={{ background: '#F9FAFB', }}>
                                                                        <tr >
                                                                            <td className="pe-3" style={{ width: '8%', paddingLeft: '15px', border: '1px solid #EAECF0', }}>
                                                                                Sr No.
                                                                            </td>
                                                                            <td>
                                                                                Comments
                                                                            </td>
                                                                            <td style={{ width: '28%', paddingRight: '15px', border: '1px solid #EAECF0', }}>
                                                                                Created By
                                                                            </td>
                                                                            {url === 'comments' ? (
                                                                            <td style={{ width: '10%', paddingRight: '15px', border: '1px solid #EAECF0', }}>
                                                                            Created By
                                                                        </td>
                                                                        ) : ''}
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {commentsList.map((comment, index) => (
                                                                            <tr key={index}>
                                                                                <td style={{ paddingLeft: '15px', border: '1px solid #EAECF0', }}>{index + 1}</td>
                                                                                <td style={{ paddingLeft: '15px', border: '1px solid #EAECF0', }}>{comment.comment}</td>
                                                                                <td style={{ paddingLeft: '15px', border: '1px solid #EAECF0', }}>{comment.created_by_name},&nbsp;&nbsp;{moment(comment?.created_at).tz(comment?.timezone).format('DD/MM/YYYY  hh:MM A')}</td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table> */}
                                                            </div>

                                                        </div>
                                                        <Modal show={addComments || editComments}>
                                                            <Modal.Header>

                                                            <h1>{editComments ? "Edit Comment" : "Add Comment"}</h1>
                                                            </Modal.Header>
                                                            <form onSubmit={handleSubmit}>
                                                                <Modal.Body>
                                                                    <div className="form-group">
                                                                        <label className="required form-label">
                                                                            Comments
                                                                        </label>
                                                                        <textarea
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder='Comments'
                                                                            value={editComments ? editformData.comment : formData.comment}
                                                                            onChange={(e) => handleInputChange(e)}
                                                                            rows={3}
                                                                            name="comment"
                                                                        >
                                                                        </textarea>
                                                                        {errorForm && errorForm.comment && (
                                                                            <div className="text-danger fs-5 mt-2">{errorForm.comment}</div>
                                                                        )}
                                                                    </div>
                                                                </Modal.Body>
                                                                <Modal.Footer>
                                                                    <div className="col-md-12"> {errorDiv} </div>
                                                                    <div className="col-md-12 text-center">

                                                                        <button
                                                                            type="submit"
                                                                            id="kt_modal_new_address_submit"
                                                                            className="btn btn-primary me-3 w-45"
                                                                            disabled={loading}
                                                                        >
                                                                            Save
                                                                        </button>

                                                                        <button
                                                                            type="button"
                                                                            onClick={editComments ? () => setEditComments(false) : () => setAddComments(false)}
                                                                            id="kt_modal_new_max_discount_value_submit"
                                                                            className="btn btn-danger me-l w-45"

                                                                        >
                                                                            Cancel
                                                                        </button>
                                                                    </div>

                                                                </Modal.Footer>
                                                            </form>

                                                        </Modal>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </main>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <GroupAdminUperSide />
            </div>
        </React.Fragment >
    );
}
