export function setMaxDate(inputNames = []) {
  const today = new Date().toISOString().split("T")[0];
  inputNames.forEach((name) => {
    const element = document.getElementsByName(name)[0];
    if (element) {
      element.setAttribute("max", today);
    }
  });
}

export function setMinDate(inputNames = [] ,remove = false) {

  // new Date(2024, 0, 11) is used. Here:
  // 2024 is the year.
  // 0 is January (months are 0-based in JavaScript).
  // 11 is the day.

  const today = new Date(2024, 11, 1).toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format
  inputNames.forEach((name) => {
    const element = document.getElementsByName(name)[0];
    if (element) {
      if (remove) {
        element.removeAttribute("min"); // Remove min attribute
      } else {
        element.setAttribute("min", today); // Set min attribute
      }
    }
  });
}



