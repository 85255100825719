import React, { useState, useEffect } from "react";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import Select from "react-select";
import Swal from "sweetalert2";
import api from "../../../../CommonApi/axios";
import { useHistory } from "react-router-dom";
import moment from "moment";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import $ from "jquery";
import headers from "../../../../CommonApi/headers";
import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useDrawer from "../../../../context/DrawerContext";
window.jquery = window.$ = $;

export default function GroupAdminTourEdit() {

    $(document).ready(function () {
        $(".phone").keypress(function (eve) {
            var allowedCharacters = /[0-9+\-.()]/;
            var inputValue = String.fromCharCode(eve.which);

            if (!allowedCharacters.test(inputValue)) {
                eve.preventDefault();
            }

            $(".phone").keyup(function (eve) {
                if ($(this).val().indexOf(".") == 0) {
                    $(this).val($(this).val().substring(1));
                }
            });
        });

        $(".number").keypress(function (eve) {
            var inputValue = String.fromCharCode(eve.which);
            if (!/^\d+$/.test(inputValue)) {
                eve.preventDefault();
            }
        });

        $(".decimal").keypress(function (eve) {
            var inputValue = String.fromCharCode(eve.which);
            if (!/^\d+$/.test(inputValue) && inputValue != ".") {
                eve.preventDefault();
            }
        });
    });

    let history = useHistory();
    let userlocation = useLocation();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const errorDiv = error ? (
        <div className="text-center alert alert-danger">{error}</div>
    ) : (
        ""
    );
    useEffect(() => {
        AllList();
    }, []);

    const { tour_id } = useParams();
    const [company, setCompany] = useState([]);
    const [client, setClient] = useState([]);
    const [location, setLocation] = useState([]);
    const [meal, setMeal] = useState([]);
    const [status, setStatus] = useState([]);
    const [currency, setCurrency] = useState([]);
    const [paymentstatus, setPaymentStatus] = useState([]);
    const [invoiceNumber, setInvoiceNumber] = useState(false)
    async function AllList() {
        try {
            const response = await api.post(`/groupadmin/tourgroup/all_setup_list`, {}, { headers });
            const AllList = response?.data?.data;
            setCompany(AllList.company);
            setClient(AllList.client);
            setLocation(AllList.location);
            setMeal(AllList.meal);
            setStatus(AllList.status);
            setCurrency(AllList.currency);
            setPaymentStatus(AllList.paymentstatus);
        } catch (err) {
            if (err.response) {
                setError(err.response?.data?.error?.message);
            }
        }

        try {
            const tourData = await api.post(`/groupadmin/tourgroup/detail_tour`, { tour_id: tour_id }, { headers });
            const TourDetails = tourData?.data?.data;
            console.log('invoice_number', TourDetails.invoice_number)
            if (TourDetails.invoice_number !== '') {
                setInvoiceNumber(true)
            }
            const ClientOptions = {
                label: TourDetails.clientinfo.client_name,
                value: TourDetails.clientinfo._id,
            };

            const CompanyOptions = {
                label: TourDetails.companyinfo.company_name,
                value: TourDetails.companyinfo._id,
            };

            const StatusOptions = {
                label: TourDetails.status,
                value: TourDetails.status_id,
            };

            const CurrencyOptions = {
                label: TourDetails.currency,
                value: TourDetails.currency_id,
            };

            const DinningOptions = {
                label: TourDetails.dinning_location,
                value: TourDetails.dinning_location_id,
            };

            const CookingOptions = {
                label: TourDetails.cooking_location,
                value: TourDetails.cooking_location_id,
            };

            const MealOptions = {
                label: TourDetails.meal_name,
                value: TourDetails.meal_id,
            };

            const PaymentStatusOptions = {
                label: TourDetails.payment_status,
                value: TourDetails.payment_status_id,
            };
            setMinDate(new Date(TourDetails.tour_date).toISOString().split('T')[0])

            setFinalData(prevFormData => ({
                ...prevFormData,
                tour_date: new Date(TourDetails.tour_date).toISOString().split('T')[0],
                payment_status_id: TourDetails.payment_status_id,
                meal_id: TourDetails.meal_id,
                cooking_location_id: TourDetails.cooking_location_id,
                dinning_location_id: TourDetails.dinning_location_id,
                currency_id: TourDetails.currency_id,
                status_id: TourDetails.status_id,
                company_id: TourDetails.companyinfo._id,
                client_id: TourDetails.clientinfo._id,
                reference: TourDetails.reference,
                referral_code: TourDetails.referral_code,
                tour_manager: TourDetails.tour_manager,
                tour_manager_no: TourDetails.tour_manager_no,
                total_pax: TourDetails.total_pax,
                adult: TourDetails.adult,
                kid: TourDetails.kid,
                veg: TourDetails.veg,
                non_veg: TourDetails.non_veg,
                jain: TourDetails.jain,
                adult_price: TourDetails.adult_price,
                kid_price: TourDetails.kid_price,
                total: TourDetails.total,
                // adult_surcharge: TourDetails.adult_surcharge,
                // kid_surcharge: TourDetails.kid_surcharge,
                // total_surcharge: (Number(TourDetails.kid) * Number(TourDetails.kid_surcharge) +
                // Number(TourDetails.adult_surcharge) * Number(TourDetails.adult)).toFixed(2),
            }));
            setFormData(prevFormData => ({
                ...prevFormData,
                tour_date: new Date(TourDetails.tour_date).toISOString().split('T')[0],
                payment_status_id: PaymentStatusOptions,
                meal_id: MealOptions,
                cooking_location_id: CookingOptions,
                dinning_location_id: DinningOptions,
                currency_id: CurrencyOptions,
                status_id: StatusOptions,
                company_id: CompanyOptions,
                client_id: ClientOptions,
                reference: TourDetails.reference,
                referral_code: TourDetails.referral_code,
                tour_manager: TourDetails.tour_manager,
                tour_manager_no: TourDetails.tour_manager_no,
                total_pax: String(TourDetails.total_pax),
                adult: String(TourDetails.adult),
                kid: String(TourDetails.kid),
                veg: String(TourDetails.veg),
                non_veg: String(TourDetails.non_veg),
                jain: String(TourDetails.jain),
                adult_price: String(TourDetails.adult_price),
                kid_price: String(TourDetails.kid_price),
                total: String(TourDetails.total),
                // adult_surcharge: String(TourDetails.adult_surcharge),
                // kid_surcharge: String(TourDetails.kid_surcharge),
                // total_surcharge: (Number(TourDetails.kid) * Number(TourDetails.kid_surcharge) +
                // Number(TourDetails.adult_surcharge) * Number(TourDetails.adult)).toFixed(2),
            }));
            if(TourDetails.adult_surcharge){
                setFormData(prevFormData => ({
                    ...prevFormData,
                    adult_surcharge: TourDetails.adult_surcharge
                }))
                setFinalData(prevFormData => ({
                    ...prevFormData,
                    adult_surcharge: TourDetails.adult_surcharge
                }))
            }
            if(TourDetails.kid_surcharge){
                setFormData(prevFormData => ({
                    ...prevFormData,
                    kid_surcharge: TourDetails.kid_surcharge
                }))
                setFinalData(prevFormData => ({
                    ...prevFormData,
                    kid_surcharge: TourDetails.kid_surcharge
                }))
            }
            setFormData(prevFormData => ({
                ...prevFormData,
                total_surcharge: (Number(formData.kid) * Number(formData.kid_surcharge) +
                Number(formData.adult_surcharge) * Number(formData.adult)).toFixed(2),
            }))
            setFinalData(prevFormData => ({
                ...prevFormData,
                total_surcharge: (Number(finalData.kid) * Number(finalData.kid_surcharge) +
                Number(finalData.adult_surcharge) * Number(finalData.adult)).toFixed(2),
            }))
            console.log(formData,":::::::::::1")
        } catch (err) {
            if (err.response) {
                setError(err.response?.data?.error?.message);
            }
        }
    }

    const [minDate, setMinDate] = useState('');

    const [formData, setFormData] = useState({
        tour_id: tour_id,
        tour_date: '',
        reference: '',
        company_id: '',
        client_id: '',
        referral_code: '',
        dinning_location_id: '',
        cooking_location_id: '',
        meal_id: '',
        tour_manager: '',
        tour_manager_no: '',
        total_pax: '0',
        adult: '0',
        kid: '0',
        veg: '0',
        non_veg: '0',
        jain: '0',
        adult_price: '0.00',
        kid_price: '0.00',
        total: '0.00',
        currency_id: '',
        status_id: '',
        payment_status_id: '',
        adult_surcharge: "0.00",
        kid_surcharge: "0.00",
        total_surcharge: "0.00"
    });
    // formData.total_surcharge:adut

    const [finalData, setFinalData] = useState({
        tour_id: tour_id,
        tour_date: '',
        reference: '',
        company_id: '',
        client_id: '',
        referral_code: '',
        dinning_location_id: '',
        cooking_location_id: '',
        meal_id: '',
        tour_manager: '',
        tour_manager_no: '',
        total_pax: '0',
        adult: '0',
        kid: '0',
        veg: '0',
        non_veg: '0',
        jain: '0',
        adult_price: '0.00',
        kid_price: '0.00',
        total: '0.00',
        currency_id: '',
        status_id: '',
        payment_status_id: '',
        adult_surcharge: "0.00",
        kid_surcharge: "0.00",
        total_surcharge: "0.00"
    });


    const handleSelectChange = (fieldName, selectedOption) => {
        setFormData(prevState => ({
            ...prevState,
            [fieldName]: selectedOption // Save the full selectedOption in formData
        }));
        setFinalData(prevState => ({
            ...prevState,
            [fieldName]: selectedOption.value // Save only the value in Finadata
        }));
    };

    const handleInputChange = (fieldName, value) => {
        setFormData(prevState => ({
            ...prevState,
            [fieldName]: value // Save only the value in Finadata
        }));
        setFinalData(prevState => ({
            ...prevState,
            [fieldName]: value // Save only the value in Finadata
        }));


    };


    const handleCalculation = (fieldName, value) => {
        let totalPax = 0;
        let Totalprice = 0;
        let total_surcharge = 0;
        if (fieldName == "adult_price") {
          Totalprice =
            Number(value) * Number(formData.adult) +
            Number(formData.kid) * Number(formData.kid_price);
          setFormData((prevState) => ({
            ...prevState,
            total: Totalprice.toFixed(2),
          }));
          setFinalData((prevState) => ({
            ...prevState,
            total: Totalprice.toFixed(2),
          }));
        } else if (fieldName == "kid_price") {
          Totalprice =
            Number(value) * Number(formData.kid) +
            Number(formData.adult) * Number(formData.adult_price);
          setFormData((prevState) => ({
            ...prevState,
            total: Totalprice.toFixed(2),
          }));
          setFinalData((prevState) => ({
            ...prevState,
            total: Totalprice.toFixed(2),
          }));
        } else if (fieldName == "adult") {
          totalPax = Number(value) + Number(formData.kid);
          total_surcharge =
            Number(formData.kid) * Number(formData.kid_surcharge) +
            Number(formData.adult_surcharge) * Number(value);
          Totalprice =
            Number(formData.kid) * Number(formData.kid_price) +
            Number(value) * Number(formData.adult_price);
          setFormData((prevState) => ({
            ...prevState,
            total_pax: totalPax.toString(), // Convert to string
            total_surcharge: total_surcharge.toFixed(2),
            total: Totalprice.toFixed(2), // Convert to string
          }));
          setFinalData((prevState) => ({
            ...prevState,
            total_surcharge: total_surcharge.toFixed(2),
            total_pax: totalPax.toString(), // Convert to string
            total: Totalprice.toFixed(2), // Convert to string
          }));
        } else if (fieldName == "adult_surcharge") {
          // adult_surcharge = Number(value) + Number(formData.kid_surcharge);
          let total_surcharge =
            Number(formData.kid) * Number(formData.kid_surcharge) +
            Number(value) * Number(formData.adult);
          setFormData((prevState) => ({
            ...prevState,
            // total_pax: totalPax.toString(), // Convert to string
            total_surcharge: total_surcharge.toFixed(2), // Convert to string
          }));
          setFinalData((prevState) => ({
            ...prevState,
            total_surcharge: total_surcharge.toFixed(2), // Convert to string
            // total: Totalprice.toFixed(2), // Convert to string
          }));
        }  else if (fieldName == "kid_surcharge") {
          // adult_surcharge = Number(value) + Number(formData.kid_surcharge);
          let total_surcharge =
            Number(formData.adult) * Number(formData.adult_surcharge) +
            Number(value) * Number(formData.kid);
          setFormData((prevState) => ({
            ...prevState,
            // total_pax: totalPax.toString(), // Convert to string
            total_surcharge: total_surcharge.toFixed(2), // Convert to string
          }));
          setFinalData((prevState) => ({
            ...prevState,
            total_surcharge: total_surcharge.toFixed(2), // Convert to string
            // total: Totalprice.toFixed(2), // Convert to string
          }));
        } else {
          totalPax = Number(value) + Number(formData.adult);
          total_surcharge =
            Number(formData.adult) * Number(formData.adult_surcharge) +
            Number(formData.kid_surcharge) * Number(value);
          Totalprice =
            Number(value) * Number(formData.kid_price) +
            Number(formData.adult) * Number(formData.adult_price);
          setFormData((prevState) => ({
            ...prevState,
            total_pax: totalPax.toString(), // Convert to string
            total_surcharge: total_surcharge.toString(),
            total: Totalprice.toFixed(2), // Convert to string
          }));
          setFinalData((prevState) => ({
            ...prevState,
            total_surcharge: total_surcharge.toString(),
            total_pax: totalPax.toString(), // Convert to string
            total: Totalprice.toFixed(2), // Convert to string
          }));
        }
    
        setFormData((prevState) => ({
          ...prevState,
          [fieldName]: value, // Save only the value in Finadata
        }));
        setFinalData((prevState) => ({
          ...prevState,
          [fieldName]: value, // Save only the value in Finadata
        }));
      };
    // const handleCalculation = (fieldName, value) => {

    //     let totalPax = 0;
    //     let Totalprice = 0;
    //     if (fieldName == 'adult_price') {

    //         Totalprice = Number(value) * Number(formData.adult) + Number(formData.kid) * Number(formData.kid_price)
    //         setFormData(prevState => ({
    //             ...prevState,
    //             total: Totalprice.toFixed(2)
    //         }));
    //         setFinalData(prevState => ({
    //             ...prevState,
    //             total: Totalprice.toFixed(2)
    //         }));
    //     }
    //     else if (fieldName == 'kid_price') {

    //         Totalprice = Number(value) * Number(formData.kid) + Number(formData.adult) * Number(formData.adult_price)
    //         setFormData(prevState => ({
    //             ...prevState,
    //             total: Totalprice.toFixed(2)
    //         }));
    //         setFinalData(prevState => ({
    //             ...prevState,
    //             total: Totalprice.toFixed(2)
    //         }));

    //     }
    //     else if (fieldName == 'adult') {

    //         totalPax = Number(value) + Number(formData.kid)
    //         Totalprice = Number(formData.kid) * Number(formData.kid_price) + Number(value) * Number(formData.adult_price);
    //         setFormData(prevState => ({
    //             ...prevState,
    //             total_pax: totalPax.toString(), // Convert to string
    //             total: Totalprice.toFixed(2) // Convert to string
    //         }));
    //         setFinalData(prevState => ({
    //             ...prevState,
    //             total_pax: totalPax.toString(), // Convert to string
    //             total: Totalprice.toFixed(2) // Convert to string
    //         }));

    //     }
    //     else {
    //         totalPax = Number(value) + Number(formData.adult)
    //         Totalprice = Number(value) * Number(formData.kid_price) + Number(formData.adult) * Number(formData.adult_price);
    //         setFormData(prevState => ({
    //             ...prevState,
    //             total_pax: totalPax.toString(), // Convert to string
    //             total: Totalprice.toFixed(2) // Convert to string
    //         }));
    //         setFinalData(prevState => ({
    //             ...prevState,
    //             total_pax: totalPax.toString(), // Convert to string
    //             total: Totalprice.toFixed(2) // Convert to string
    //         }));
    //     }

    //     setFormData(prevState => ({
    //         ...prevState,
    //         [fieldName]: value // Save only the value in Finadata
    //     }));
    //     setFinalData(prevState => ({
    //         ...prevState,
    //         [fieldName]: value // Save only the value in Finadata
    //     }));

    // }


    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent the default form submission behavior
        setError(null);
        setLoading(true);
        Swal.fire({
            title: 'Please wait...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            showConfirmButton: false,
            willOpen: () => {
                Swal.showLoading();
            }
        });

        const requiredFields = [
            'tour_date', 'reference', 'company_id', 'client_id', 'dinning_location_id', 'cooking_location_id', 'meal_id', 'total_pax', 'adult', 'kid', 'veg', 'non_veg', 'jain', 'adult_price', 'kid_price', 'total', 'currency_id', 'status_id',
        ];

        for (const field of requiredFields) {
            let fieldName = field.replace(/_id$/, ''); // Remove "_id" from the end of the field name
            fieldName = fieldName.replace(/_/g, ' '); // Replace underscores with spaces
            const errorMessage = `${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)} is required`; // Capitalize the first letter of the field name

            if (!formData[field]) {
                // Set custom error message
                Swal.close()
                setError(errorMessage);
                setLoading(false); // Stop loading
                return;
            }
        }

        api
            .post(`/groupadmin/tourgroup/edit_tour`, finalData, { headers })
            .then((result) => {
                Swal.close()

                Swal.fire({
                    position: "center-center",
                    icon: "success",
                    title: "Tour has been Updated successfully.",
                    showConfirmButton: false,
                    timer: 1500,
                }).then(() => {
                    setLoading(false);
                    history.push("/group_admin/tour");
                    // window.location.href = '/group_admin/tour/list';
                });
            })
            .catch((err) => {
                if (err.response) {
                    Swal.close()
                    toast.error(err?.response?.data?.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setLoading(false);
                }
            });

    };

const { drawerPos, handleDrawer , maingetViewDetails } = useDrawer();



    return (
        <React.Fragment>
            <div
                id="kt_body"
                className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
            >
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
                        <div
                            className="wrapper d-flex flex-column flex-row-fluid"
                            id="kt_wrapper"
                        >
                            <GroupAdminHeader />
                            <div
                                className=" d-flex flex-column flex-column-fluid"
                                id="kt_content"
                            >
                                <div id="kt_content_container" className="mx-7">
                                <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                                        <div className="d-flex flex-stack mb-7">
                                            <div
                                                className="d-flex justify-content-end"
                                                data-kt-docs-table-toolbar="base"
                                            >
                                                <h1
                                                    className="anchor fw-bolder"
                                                    id="custom-form-control"
                                                >
                                                    Edit Tour
                                                </h1>
                                            </div>
                                            <div className="d-flex flex-stack">
                                                <div
                                                    className="d-flex justify-content-end mx-2"
                                                    data-kt-docs-table-toolbar="base"
                                                >
                                                    <button
                                                        onClick={() => history.goBack()}
                                                        className="btn btn-primary"
                                                        data-kt-menu-trigger="click"
                                                        data-kt-menu-attach="parent"
                                                        data-kt-menu-placement="bottom-end"
                                                    >
                                                        Go Back
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="py-5">
                                            <div className="card card-docs mb-2">
                                                <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                                                    <div className="rounded border p-10">
                                                        <form onSubmit={handleSubmit}>
                                                            <div className="mb-10">
                                                                <h4>Tour Details</h4>
                                                            </div>
                                                            <div className="row col-12">
                                                                <div className="mb-10 col-md-6 col-12">
                                                                    <label className="required form-label">
                                                                        Date
                                                                    </label>
                                                                    <input
                                                                        type="date"
                                                                        className="form-control"
                                                                        placeholder='Date'
                                                                        required
                                                                        name="tour_date"
                                                                        value={formData.tour_date}
                                                                        onChange={(e) => handleInputChange('tour_date', e.target.value)}
                                                                        readOnly={invoiceNumber}
                                                                    />
                                                                </div>
                                                                <div className="mb-10 col-md-6 col-12">
                                                                    <label className="required form-label">
                                                                        Reference
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder='Reference'
                                                                        required
                                                                        name="reference"
                                                                        value={formData.reference}
                                                                        onChange={(e) => handleInputChange('reference', e.target.value)}
                                                                        readOnly={invoiceNumber}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row col-12">
                                                                <div className="mb-10 col-md-6">
                                                                    <label className="required form-label">
                                                                        Select Company
                                                                    </label>
                                                                    <Select
                                                                        id="select2"
                                                                        className="search-line"
                                                                        placeholder="Search Company..."
                                                                        name="company_id"
                                                                        value={formData.company_id}
                                                                        onChange={(selectedOption) => handleSelectChange('company_id', selectedOption)}
                                                                        options={company.map(item => ({
                                                                            label: item.name,
                                                                            value: item._id
                                                                        }))}
                                                                        isDisabled={invoiceNumber}

                                                                    />
                                                                </div>
                                                                <div className="mb-10 col-md-6">
                                                                    <label className="required form-label">
                                                                        Select Client
                                                                    </label>
                                                                    <Select
                                                                        required
                                                                        className="search-line"
                                                                        placeholder="Search Client..."
                                                                        name="client_id"
                                                                        value={formData.client_id}
                                                                        onChange={(selectedOption) => handleSelectChange('client_id', selectedOption)}
                                                                        options={client.map(item => ({
                                                                            label: item.name,
                                                                            value: item._id
                                                                        }))}
                                                                        isDisabled={invoiceNumber}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row col-12">
                                                                <div className="mb-10 col-md-6">
                                                                    <label className="required form-label">
                                                                        Select Status
                                                                    </label>
                                                                    <Select
                                                                        id="select2"
                                                                        className="search-line"
                                                                        placeholder="Search Status..."
                                                                        name="status_id"
                                                                        value={formData.status_id}
                                                                        onChange={(selectedOption) => handleSelectChange('status_id', selectedOption)}
                                                                        options={status.map(item => ({
                                                                            label: item.name,
                                                                            value: item._id
                                                                        }))}
                                                                        required
                                                                        isDisabled={invoiceNumber}
                                                                    />
                                                                </div>
                                                                <div className="mb-10 col-md-6 col-12">
                                                                    <label className="form-label">
                                                                        Referral Code
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder='Referral Code'
                                                                        name="referral_code"
                                                                        value={formData.referral_code}
                                                                        onChange={(e) => handleInputChange('referral_code', e.target.value)}
                                                                        readOnly={invoiceNumber}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row col-12">
                                                                <div className="mb-10 col-md-6">
                                                                    <label className="required form-label">
                                                                        Select Dining Location
                                                                    </label>
                                                                    <Select
                                                                        id="select2"
                                                                        className="search-line"
                                                                        placeholder="Search Dining Location..."
                                                                        name="dinning_location_id"
                                                                        value={formData.dinning_location_id}
                                                                        onChange={(selectedOption) => handleSelectChange('dinning_location_id', selectedOption)}
                                                                        options={location.map(item => ({
                                                                            label: item.name,
                                                                            value: item._id
                                                                        }))}
                                                                        required
                                                                        isDisabled={invoiceNumber}
                                                                    />
                                                                </div>
                                                                <div className="mb-10 col-md-6">
                                                                    <label className="required form-label">
                                                                        Select Cooking Location
                                                                    </label>
                                                                    <Select
                                                                        required
                                                                        className="search-line"
                                                                        placeholder="Search Cooking Location..."
                                                                        name="cooking_location_id"
                                                                        value={formData.cooking_location_id}
                                                                        onChange={(selectedOption) => handleSelectChange('cooking_location_id', selectedOption)}
                                                                        options={location.map(item => ({
                                                                            label: item.name,
                                                                            value: item._id
                                                                        }))}
                                                                        isDisabled={invoiceNumber}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row col-12">
                                                                <div className="mb-10 col-md-6 col-12">
                                                                    <label className="form-label">
                                                                        Manager Name
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder='Manager Name'
                                                                        name="tour_manager"
                                                                        value={formData.tour_manager}
                                                                        onChange={(e) => handleInputChange('tour_manager', e.target.value)}
                                                                        readOnly={invoiceNumber}
                                                                    />
                                                                </div>
                                                                <div className="mb-10 col-md-6 col-12">
                                                                    <label className="form-label">
                                                                        Manager Contact Number
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="phone form-control"
                                                                        placeholder='Manager Contact Number'
                                                                        name="tour_manager_no"
                                                                        value={formData.tour_manager_no}
                                                                        onChange={(e) => handleInputChange('tour_manager_no', e.target.value)}
                                                                        readOnly={invoiceNumber}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row col-12">
                                                                <div className="mb-10 col-md-12">
                                                                    <label className="required form-label">
                                                                        Meal
                                                                    </label>
                                                                    <Select
                                                                        id="select2"
                                                                        required
                                                                        className="search-line"
                                                                        placeholder="Search Meal..."
                                                                        name="meal_id"
                                                                        value={formData.meal_id}
                                                                        onChange={(selectedOption) => handleSelectChange('meal_id', selectedOption)}
                                                                        options={meal.map(item => ({
                                                                            label: item.name,
                                                                            value: item._id
                                                                        }))}
                                                                        isDisabled={invoiceNumber}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row col-12">
                                                                <div className="mb-10 col-md-4 col-12">
                                                                    <label className="required form-label">
                                                                        No of Adults
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="number form-control"
                                                                        placeholder='No of Adults'
                                                                        name="adult"
                                                                        value={formData.adult}
                                                                        onChange={(e) => handleCalculation('adult', e.target.value)}
                                                                        required
                                                                        readOnly={invoiceNumber}
                                                                    />
                                                                </div>
                                                                <div className="mb-10 col-md-4 col-12">
                                                                    <label className="required form-label">
                                                                        No of Kids
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="number form-control"
                                                                        placeholder='No of Kids'
                                                                        name="kid"
                                                                        value={formData.kid}
                                                                        onChange={(e) => handleCalculation('kid', e.target.value)}
                                                                        required
                                                                        readOnly={invoiceNumber}
                                                                    />
                                                                </div>
                                                                <div className="mb-10 col-md-4 col-12">
                                                                    <label className="number form-label">
                                                                        Total People
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="number form-control"
                                                                        placeholder='Total People'
                                                                        name="total_pax"
                                                                        value={formData.total_pax}
                                                                        readOnly
                                                                        required
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row col-12">
                                                                <div className="mb-10 col-md-4 col-12">
                                                                    <label className="required form-label">
                                                                        No of Vegetarian Meals
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="number form-control"
                                                                        placeholder='No of Vegetarian Meals'
                                                                        name="veg"
                                                                        value={formData.veg}
                                                                        onChange={(e) => handleInputChange('veg', e.target.value)}
                                                                        required
                                                                        readOnly={invoiceNumber}
                                                                    />
                                                                </div>
                                                                <div className="mb-10 col-md-4 col-12">
                                                                    <label className="required form-label">
                                                                        No of Jain Meals
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="number form-control"
                                                                        placeholder='No of Jain Meals'
                                                                        name="jain"
                                                                        value={formData.jain}
                                                                        onChange={(e) => handleInputChange('jain', e.target.value)}
                                                                        required
                                                                        readOnly={invoiceNumber}
                                                                    />
                                                                </div>
                                                                <div className="mb-10 col-md-4 col-12">
                                                                    <label className="required form-label">
                                                                        No of Non Vegetarian Meals
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="number form-control"
                                                                        placeholder='No of Non Vegetarian Meals'
                                                                        name="non_veg"
                                                                        value={formData.non_veg}
                                                                        onChange={(e) => handleInputChange('non_veg', e.target.value)}
                                                                        required
                                                                        readOnly={invoiceNumber}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row col-12">
                                                                <div className="mb-10 col-md-4 col-12">
                                                                    <label className="required form-label">
                                                                        Adult Unit Price
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="decimal form-control"
                                                                        placeholder='Adult Unit Price'
                                                                        name="adult_price"
                                                                        value={formData.adult_price}
                                                                        onChange={(e) => handleCalculation('adult_price', e.target.value)}
                                                                        required
                                                                        readOnly={invoiceNumber}
                                                                    />
                                                                </div>
                                                                <div className="mb-10 col-md-4 col-12">
                                                                    <label className="required form-label">
                                                                        Kids Unit Price
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="decimal form-control"
                                                                        placeholder='Kids Unit Price'
                                                                        name="kid_price"
                                                                        value={formData.kid_price}
                                                                        onChange={(e) => handleCalculation('kid_price', e.target.value)}
                                                                        required
                                                                        readOnly={invoiceNumber}
                                                                    />
                                                                </div>
                                                                <div className="mb-10 col-md-4 col-12">
                                                                    <label className="required form-label">
                                                                        Total Price
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="decimal form-control"
                                                                        placeholder='Total Price'
                                                                        name="total"
                                                                        value={formData.total}
                                                                        required
                                                                        readOnly
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row col-12">
                                                            <div className="mb-10 col-md-4 col-12">
                                                            <label className="form-label">
                                                                Adults Surcharge 
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="decimal form-control"
                                                                placeholder="Adults Surcharge"
                                                                name="adult_surcharge"
                                                                value={formData.adult_surcharge}
                                                                onChange={(e) =>
                                                                handleCalculation(
                                                                    "adult_surcharge",
                                                                    e.target.value
                                                                )
                                                                }
                                                                required
                                                            />
                                                            </div>
                                                            <div className="mb-10 col-md-4 col-12">
                                                            <label className="form-label">
                                                                Kids Surcharge
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="decimal form-control"
                                                                placeholder="Kids Surcharge"
                                                                name="kid_surcharge"
                                                                value={formData.kid_surcharge}
                                                                onChange={(e) =>
                                                                handleCalculation(
                                                                    "kid_surcharge",
                                                                    e.target.value
                                                                )
                                                                }
                                                                required
                                                            />
                                                            </div>
                                                            <div className="mb-10 col-md-4 col-12">
                                                            <label className="form-label">
                                                                Total Surcharge
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="number form-control"
                                                                placeholder="Total Surcharge"
                                                                name="total_surcharge"
                                                                value={formData.total_surcharge}
                                                                required
                                                                readOnly
                                                            />
                                                            </div>
                                                            </div>
                                                            <div className="row col-12">
                                                                <div className="mb-10 col-md-6 col-12">
                                                                    <label className="required form-label">
                                                                        Payment Currency
                                                                    </label>
                                                                    <Select
                                                                        id="select2"
                                                                        className="search-line"
                                                                        placeholder="Search Payment Currency..."
                                                                        name="currency_id"
                                                                        value={formData.currency_id}
                                                                        onChange={(selectedOption) => handleSelectChange('currency_id', selectedOption)}
                                                                        options={currency.map(item => ({
                                                                            label: item.name,
                                                                            value: item._id
                                                                        }))}
                                                                        required
                                                                        isDisabled={invoiceNumber}
                                                                    />
                                                                </div>
                                                                <div className="mb-10 col-md-6 col-12">
                                                                    <label className="form-label">
                                                                        Payment Status
                                                                    </label>
                                                                    <Select
                                                                        id="select2"
                                                                        className="search-line"
                                                                        placeholder="Search Payment Currency..."
                                                                        name="payment_status_id"
                                                                        value={formData.payment_status_id}
                                                                        onChange={(selectedOption) => handleSelectChange('payment_status_id', selectedOption)}
                                                                        options={paymentstatus.map(item => ({
                                                                            label: item.name,
                                                                            value: item._id
                                                                        }))}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-12"> {errorDiv} </div>
                                                            <div className="col-md-12 text-center">
                                                                <br />
                                                                <div
                                                                    className="loader"
                                                                    style={{ display: "none" }}
                                                                >
                                                                    <img src="/assets/loader.gif" />
                                                                </div>
                                                            </div>

                                                            {/* <div className="col-md-12"> {errorDiv} </div> */}
                                                            <div className="modal-footer flex-center">
                                                                <button
                                                                    type="submit"
                                                                    id="kt_modal_new_address_submit"
                                                                    className="btn btn-primary"
                                                                    disabled={loading}
                                                                >
                                                                    Save
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    onClick={() => history.goBack()}
                                                                    id="kt_modal_new_max_discount_value_submit"
                                                                    className="btn btn-danger"
                                                                >
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </main>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <GroupAdminUperSide />
            </div>
        </React.Fragment>
    );
}
